.container {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  font-family: Arial, sans-serif;
  max-width: 1800px;
  margin: 10px auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 225px); /* Set the height to the viewport height minus the footer height */
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.header-right {
  display: flex;
  align-items: center;
}

.logout-button {
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 15px;
  margin-left: 10px;
}

.logout-button:hover {
  background-color: #3e8e41;
}

.left-column {
  width: 20%;
  min-width: 200px;
  height: 100%;
  overflow-y: auto;
  border-right: 1px solid #ccc;
  padding: 10px;
}

.center-column {
  width: 60%;
  min-width: 800px;
  padding: 10px;
}

.right-column {
  width: 20%;
  min-width: 200px;
  padding: 10px;
}

.conversation-wrapper {
  height: 100%;
  overflow-y: auto;
}

.footer {
  position: fixed;
  max-width: 1800px;
  margin: 10px auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #ccc;
  text-align: center;
  font-size: 12px;
  color: #666;
}

.input-container {
  display: flex;
  gap: 10px;
}

.textarea {
  padding: 10px;
  width: 100%;
  height: 100px;
  resize: vertical;
  font-size: 16px;
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button {
  padding: 10px 15px;
  font-size: 14px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.conversation-container {
  margin-bottom: 20px;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.conversation-list {
  padding: 20px;
}

.conversation-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.conversation-list li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.conversation-list li:last-child {
  border-bottom: none;
}

.conversation-list-button {
  width: 100%;
  padding: 10px;
  background-color: #f9f9f9;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.conversation-list-button:hover {
  background-color: #f2f2f2;
}

.project-list-container {
  max-height: 70%; /* adjust the max height as needed */
  overflow-y: auto; /* add a vertical scrollbar if the content overflows */
}

.project-list {
  padding: 0; /* remove default padding */
  list-style: none; /* remove default list styles */
  margin: 0; /* remove default margin */
  font-size: smaller;
}

.project-box {
  padding: 8px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: top;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 70vw;
  height: 60vh;
  overflow-y: auto;
  margin-top: 20px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.modal-header h2 {
  margin: 0;
}

.modal-header button {
  background-color: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.modal-body {
  padding: 20px;
}

.modal-body-path {
  width: 100%;
  padding: 4px;
  margin-bottom: 10px;
}

.modal-body-content {
  height: 38vh;
  width: 100%;
  padding: 4px;
}

.modal-footer {
  padding: 10px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
}

.button-small {
  padding: 4px 4px;
  font-size: 10px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 2px;
}

.button-small:hover {
  background-color: #3e8e41;
}

.button-small-red {
  padding: 4px 4px;
  font-size: 10px;
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 2px;
}

.button-small-red:hover {
  background-color: #cc0000;
}

.button-medium {
  padding: 10px 10px;
  font-size: 12px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 4px;
}

.button-medium:hover {
  background-color: #3e8e41;
}

.resized-image {
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
}

/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* Landing Page Styles */
.landing-container {
  text-align: center;
  padding: 20px;
  max-width: 600px;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.landing-logo {
  font-size: 2.5rem;
  font-weight: bold;
  color: #00b050; /* Cheerful green */
  margin-bottom: 10px;
}

.landing-tagline {
  font-size: 1.2rem;
  color: #666666; /* Neutral contrasting color */
  margin-bottom: 20px;
}

.landing-form {
  margin-bottom: 20px;
}

.landing-input {
  display: block;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px auto;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.landing-button {
  display: inline-block;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #0073e6; /* Contrasting blue */
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.landing-button:hover {
  background-color: #005bb5; /* Darker blue on hover */
}

.landing-footer {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #999999;
}
